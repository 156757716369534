
.background {
  background-image: url("../public/assets/bgbig.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Horizontally center content */
  align-items: center; /* Vertically center content */
  overflow: hidden;


}


@keyframes rotate360 {
  0% {
    transform: rotateY(0deg);
    margin-top: 0;
  }
  100% {
    transform: rotateY(360deg);
  }
}

.animated-arrow {
  animation: rotate360 2s linear infinite; /* Adjust the animation duration as needed */
  cursor: pointer;
}

